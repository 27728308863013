import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { conversionTypes } from "../integration/gtag"
import ConversionLink from "../components/conversion-link"
import { useSiteMetadata } from "../hooks/use-site-metadata"
import "../components/style.css"

const ContactPage = () => {
  const { phone, email } = useSiteMetadata()
  const [submitted, setSubmitted] = React.useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()
    const { elements } = e.currentTarget

    // const to = elements.email.value;
    const name = elements.name.value
    const subject = elements.subject.value
    const message = elements.message.value
    const phone = elements.phone.value

    const body = `${message}\n\n${phone}\n${name}`

    const href = `mailto:${email}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`

    window.location.href = href

    // const fields = ["email", "name", "subject", "message"]
    // const emailData = {}

    // fields.forEach(field => {
    //   emailData[field] = e.currentTarget.elements[field].value
    // })

    // firebase
    //   .database()
    //   .ref("emails")
    //   .push()
    //   .set(emailData)
  }

  return (
    <Layout>
      <SEO title="צור קשר" />
      <div className="row">
        <div className="col-xs-12 col-lg-9">
          <h2>צור קשר</h2>
          <br />
          <h4>טלפון</h4>
          <h5>
            <ConversionLink
              href={`tel:${phone}`}
              dir="ltr"
              conversionId={conversionTypes.phoneContactPage}
            >
              {phone}
            </ConversionLink>
          </h5>
          <br />
          <h4>מייל</h4>
          <form
            onSubmit={handleSubmit}
            className={`${submitted ? "submitted" : ""}`}
          >
            <div className="form-group">
              <label htmlFor="name">שם</label>
              <input
                type="text"
                className="form-control"
                id="name"
                placeholder="שם"
              />
            </div>
            {/* <div className="form-group">
              <label htmlFor="email">כתובת מייל</label>
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="כתובת מייל"
              />
            </div> */}
            <div className="form-group">
              <label htmlFor="phone">מספר טלפון</label>
              <input
                type="phone"
                className="form-control"
                id="phone"
                placeholder="מספר טלפון"
              />
            </div>
            <div className="form-group">
              <label htmlFor="subject">נושא</label>
              <input
                type="text"
                className="form-control"
                id="subject"
                placeholder="נושא"
              />
            </div>
            <div className="form-group">
              <label htmlFor="message">הודעה</label>
              <textarea
                type="text"
                className="form-control"
                id="message"
                style={{ minHeight: "200px" }}
              />
            </div>
            <button type="submit" className="btn btn-primary">
              שליחה
            </button>
          </form>
        </div>
        <div className="d-none d-lg-block col-lg-3 sidebar">
          <h4>שעות פעילות:</h4>
          <p className="row d-flex">
            <div className="d-inline-block">
              <div>יום א' - ה':</div>
              <div> שישי - שבת:</div>
            </div>
            <div className="d-inline-block">
              <div>7:00 - 21:00</div>
              <div>7:00 - 21:00</div>
            </div>
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default ContactPage
